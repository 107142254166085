export const domains = [
  { tenantId: 'hpsa', domain: 'Promart.pe' },
  { tenantId: 'tpsa', domain: 'Oechsle.pe' },
  { tenantId: 'pvea', domain: 'plazaVea.pe' }
];

export const typeSolution = [
  { id: 1, name: 'Cambio por otro producto' },
  { id: 2, name: 'Devolución y reembolso al mismo medio de pago' },
  { id: 3, name: 'Vale para cambio (nota de crédito)' }
]

export const storesOnline = [
  { tenantId: 'hpsa', storeCode: null },
  { tenantId: 'tpsa', storeCode: 204 },
  { tenantId: 'pvea', storeCode: null },
  { tenantId: 'rplaza', storeCode: null }
];

export const orientalStoresByTenant = [
  { tenantId: 'hpsa', stores: [] },
  { tenantId: 'tpsa', stores: [ '129', '130' ] },
  { tenantId: 'pvea', stores: [] },
  { tenantId: 'rplaza', stores: [] }
]

export enum TRACKING_GROUP_ENUM {
  CHANGE_REQUEST = "CHANGE_REQUEST",
  RETURN_REQUEST = "RETURN_REQUEST",
  CHANGE_REQUEST_SELLER = "CHANGE_REQUEST_SELLER",
  RETURN_REQUEST_SELLER = "RETURN_REQUEST_SELLER",
  SERVICE_TECHNICAL = "SERVICE_TECHNICAL"
};

export enum REQUEST_TYPE_ENUM {
  CHANGE_REQUEST = "CHANGE_REQUEST",
  RETURN_REQUEST = "RETURN_REQUEST",
  CHANGE_SELLER_CENTER_REQUEST = "CHANGE_SELLER_CENTER_REQUEST",
  RETURN_SELLER_CENTER_REQUEST = "RETURN_SELLER_CENTER_REQUEST"
};

export const trackingsGroupCD: TRACKING_GROUP_ENUM[] = [
  TRACKING_GROUP_ENUM.CHANGE_REQUEST,
  TRACKING_GROUP_ENUM.RETURN_REQUEST,
  TRACKING_GROUP_ENUM.CHANGE_REQUEST_SELLER,
  TRACKING_GROUP_ENUM.RETURN_REQUEST_SELLER
];

export enum TICKET_TYPE {
  CHANGE_REQUEST = "CHANGE_REQUEST",
  RETURN_REQUEST = "RETURN_REQUEST",
  SERVICE_TECHNICAL = "SERVICE_TECHNICAL"
};

export const ticketTypesCD: TICKET_TYPE[] = [
  TICKET_TYPE.CHANGE_REQUEST,
  TICKET_TYPE.RETURN_REQUEST
];

export const ticketTypes = new Map<string, string>([
  [ TICKET_TYPE.CHANGE_REQUEST, 'Cambio' ],
  [ TICKET_TYPE.RETURN_REQUEST, 'Devolución' ],
  [ TICKET_TYPE.SERVICE_TECHNICAL, 'Servicio Técnico' ]
]);

export enum PackageStatus {
  OPEN_PACKAGE = "OPEN_PACKAGE",
  CLOSED_PACKAGE = "CLOSED_PACKAGE",
  NONE = "NONE"
}

export const SOURCE_ID: number = 34;

const usernameFirebase = 'hpsa-ldr-web@aria.com';
const passwordFirebase = 'casa1234';

export const commons = {
  usernameFirebase,
  passwordFirebase
}